<template>
  <div class="flex flex-col bg-white">
    <PageHeader
      hideButton="true"
      title="Contact Details"
      sub_title=""
      img="/images/contact_header.webp"
    />

    <div class="flex flex-col w-full bg-white py-16">
      <div class="container mx-auto">
        <div class="flex flex-col items-center border-2 p-8 rounded-lg shadow-md">
          <p class="text-gray-900 text-xl font-bold">Cornfield Engineering Solutions Ltd</p>
          <p class="text-gray-900 text-xl font-bold">Unit 5, Brindley Court</p>
          <p class="text-gray-900 text-xl font-bold">Victoria Business Park</p>
          <p class="text-gray-900 text-xl font-bold">Brown Lees</p>
          <p class="text-gray-900 text-xl font-bold">Stoke-on-Trent</p>
          <p class="text-gray-900 text-xl font-bold">ST8 7PP</p>
          <p class="text-gray-900 text-xl font-bold">Tel: 01782 511161</p>
          <p class="text-gray-900 text-xl font-bold">Email: sales@cornfieldeng.co.uk</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full bg-gray-100 py-16">
      <div class="container mx-auto">
        <div class="flex w-full justify-around">
          <div class="w-5/12 map-container p-4 border-2 rounded shadow-md">
            <iframe
              class="w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2395.80951093021!2d-2.183945049175541!3d53.09565597982872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a430a9b84df4b%3A0x88b75b0e5758498c!2sCornfield%20Engineering%20Solutions!5e0!3m2!1sen!2suk!4v1605717810688!5m2!1sen!2suk"
              frameborder="0"
              style="border:0;"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
          <div class="w-5/12 map-container p-4 border-2 rounded shadow-md">
            <!-- component -->
            <form class="w-full max-w-lg">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    First Name
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-900 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder="Jane"
                    :value="state.contact.firstName"
                    @input="e => actions.updateContactFirstName(e.target.value)"
                  />
                  <p
                    v-if="state.contact.firstName.length === 0"
                    class="text-red-500 text-xs italic"
                  >
                    Please fill out this field.
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Last Name
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-900 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Doe"
                    :value="state.contact.lastName"
                    @input="e => actions.updateContactLastName(e.target.value)"
                  />
                  <p v-if="state.contact.lastName.length === 0" class="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    E-mail
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-900 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email"
                    type="email"
                    :value="state.contact.email"
                    @input="e => actions.updateContactEmail(e.target.value)"
                  />
                  <p v-if="state.contact.email.length === 0" class="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Message
                  </label>
                  <textarea
                    class=" no-resize appearance-none block w-full bg-gray-200 text-gray-900 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    id="message"
                    :value="state.contact.message"
                    @input="e => actions.updateContactMessage(e.target.value)"
                  ></textarea>
                  <p v-if="state.contact.message.length < 10" class="text-red-500 text-xs italic">
                    Please fill out this field - Min 10 Characters
                  </p>
                </div>
              </div>
              <div class="md:flex md:items-center">
                <div class="md:w-1/3">
                  <button
                    class="shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="button"
                  >
                    Send
                  </button>
                </div>
                <div class="md:w-2/3"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader";

export default {
  name: "Contact",
  components: { PageHeader },
};
</script>
<style scoped>
.map-container {
  min-height: 450px !important;
}
</style>
